import React, { useEffect } from 'react';
import { BuilderComponent } from '@builder.io/react';
import { CheckoutProvider } from '~/context/checkout';
import 'cms/builder/registry';
import { getParam } from '~/utils/url'
import {
  URL_PROMO_CODES_OUTSTANDING,
  URL_PROMO_CODES_INITIAL,
} from '~/utils/constants'
interface Props {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  header: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  footer: any;
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  page: any;
}

const PROMO_URL_PARAM_KEY = 'promo'
const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  children,
  footer,
  page,
}) => {
  useEffect(()=> {
    const urlPromos = getParam(PROMO_URL_PARAM_KEY)
    if (urlPromos) {
      localStorage.setItem(URL_PROMO_CODES_INITIAL, urlPromos)
      localStorage.setItem(URL_PROMO_CODES_OUTSTANDING, urlPromos)

    }
    
  },[]);
  const hideNav = page?.data?.hideNav;
  const hideFooter = page?.data?.hideFooter;
  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  // if (!hasMounted) {
  //   return null;
  // }
  return (
    <>
      {!hideNav && hasMounted && (
        <header className="header fixed-top pt-0">
          <BuilderComponent model="header" content={header} />
        </header>
      )}

      <main role="main" id="main-content-wrapper">
        <CheckoutProvider>{children}</CheckoutProvider>
      </main>

      {!hideFooter && (
        <footer>
          <BuilderComponent model="footer" content={footer} />
        </footer>
      )}
    </>
  );
};

export default Layout;
