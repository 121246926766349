export const CHECKOUT_DATA_KEY_ADDRESS = 'pl-address-data'
export const CHECKOUT_DATA_KEY_REPORT = 'pl-report-data'

export const CHECKOUT_STEP_CHECKOUT = 'CHECKOUT_STEP_CHECKOUT'
export const CHECKOUT_STEP_DEFAULT = 'CHECKOUT_STEP_DEFAULT'
export const CHECKOUT_STEP_CHECKOUT_COMPLETE = 'CHECKOUT_STEP_CHECKOUT_COMPLETE'
export const CHECKOUT_STEP_INTERSTITIAL = 'CHECKOUT_STEP_INTERSTITIAL'
export const CHECKOUT_STEP_REPORT_PREVIEW = 'CHECKOUT_STEP_REPORT_PREVIEW'

export const CHECKOUT_PATHS = {
  [CHECKOUT_STEP_CHECKOUT]: 'checkout',
  [CHECKOUT_STEP_DEFAULT]: '',
  [CHECKOUT_STEP_INTERSTITIAL]: 'interstitial',
  [CHECKOUT_STEP_REPORT_PREVIEW]: 'report',
}

export const URL_PROMO_CODES_INITIAL = 'pl-promo-codes-initial'
export const URL_PROMO_CODES_OUTSTANDING = 'pl-promo-codes-outstanding'

export const RETRY_PREVIEW_REPORT_STATUS_CODE = 503
export const RETRY_GET_PREVIEW_REPORT_STATUS_CODE = 504

export const PAYMENT_FORM_HEIGHT_THRESHOLD = 640;
export const PAYMENT_CHECK_INTERVAL = 1000;
export const MAX_PAYMENT_LOADING_TIMEOUT = 45000;

export const ERROR_MESSAGES = {
  ADDRESS: 'Address is required',
  PREVIEW_ID: 'Preview ID is required',
  M2M: 'Could not generate M2M token',
  SERVICE: 'Service Unavailable',
  PREVIEW_REPORT: 'Preview Report Error',
  METHOD_NOT_ALLOWED: 'Method Not Allowed',
  INTERNAL_SERVER: 'Internal Server Error'
} as const;