import './RatingCard.scss';

import useResizeObserver from '@react-hook/resize-observer';
import { RemixiconComponentType } from '@remixicon/react';
import { Case } from 'change-case-all';
import cx from 'classnames';
import React, { HTMLProps, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { RatingsEnum } from '../../types';
import { IconNameType } from '../Icons/Icon';
import SectionHeadingGroup from '../SectionHeadingGroup';

type RatingCardProps = HTMLProps<HTMLBodyElement> & {
  icon?: IconNameType | React.ReactNode | RemixiconComponentType;
  iconDesktop?: IconNameType | React.ReactNode | RemixiconComponentType;
  label: string;
  rating: string | RatingsEnum | boolean | null;
  customRatingLabel?: string;
};

const useSize = (target: any) => {
  const [size, setSize] = useState<DOMRect | null>();

  const debounced = useDebouncedCallback((value) => {
    setSize(value);
  }, 250);

  React.useLayoutEffect(() => {
    target &&
      target.current &&
      debounced(target.current.getBoundingClientRect());
  }, [target]);

  if (typeof window !== 'undefined') {
    // Where the magic happens
    useResizeObserver(target, (entry) => debounced(entry.contentRect));
  }

  return size;
};

const RatingCard: React.FC<RatingCardProps> = ({
  rating,
  label,
  icon,
  iconDesktop,
  className,
  customRatingLabel,
}) => {
  const ratingCardCX = cx('RatingCard', {
    'very-high': rating === true,
    'very-low': rating === false,
    [`${rating}`]: typeof rating === 'string',
    [`${className}`]: className,
  });

  return (
    <div className={ratingCardCX}>
      <div className="rating-card-content flex min-h-0 items-center justify-start gap-2 px-10 py-5">
        <SectionHeadingGroup
          hasUnderline={false}
          level={3}
          className="!mb-0 py-1.5"
          color="pl-gray-white"
          heading={label}
          description={
            customRatingLabel
              ? customRatingLabel
              : typeof rating === 'boolean'
                ? ''
                : rating
                  ? `${Case.title((rating as string).replace('-', ' '))} Risk`
                  : 'Unknown Risk'
          }
          icon={icon}
          iconDesktop={iconDesktop}
        />

        {/* {icon && (
          <div className="icon mr-2 flex min-w-[40px]">
            <Icon
              icon={iconSm && size && size.width < 640 ? iconSm : icon}
              color="#FFFFFF"
              size={iconSm && size && size.width < 640 ? 40 : 'xl'}
            />
          </div>
        )} */}
        {/* <div className="label-container">
          <SectionHeading hasUnderline={false} level={3} color="white">
            {label}
          </SectionHeading>
          <div className="sub-label font-raleway font-semibold">
            {customRatingLabel
              ? customRatingLabel
              : rating
                ? `${Case.title(rating.replace('-', ' '))} Risk`
                : 'Unknown Risk'}
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default RatingCard;
