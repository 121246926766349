import { builder, Builder, withChildren } from '@builder.io/react'
import dynamic from 'next/dynamic'
builder.init(process.env.NEXT_PUBLIC_BUILDER_API_KEY)
import Button from '~/components/UI-Kit/Button'
import { colors } from '../../tailwindBaseConfig'

Builder.registerComponent(Button, {
  name: 'Core:Button',
  override: true,
  inputs: [
    {
      name: 'text',
      type: 'text',
      defaultValue: 'Click me!',
      bubble: true,
    },
    {
      name: 'link',
      type: 'url',
      bubble: true,
    },
    {
      name: 'openLinkInNewTab',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Open link in new tab',
    },
    {
      name: 'openReportsModal',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Open Reports Modal',
    },
    {
      name: 'variant',
      type: 'string',
      defaultValue: 'primary',
      enum: ['primary', 'secondary', 'text'],
    },
    {
      name: 'radius',
      type: 'string',
      defaultValue: 'none',
      enum: ['none', 'small', 'medium', 'large', 'full'],
    },
    {
      name: 'size',
      type: 'string',
      defaultValue: 'sm',
      enum: ['sm', 'md', 'lg', 'xl', 'full'],
    },
    { name: 'textColor', type: 'color' },
    {
      name: 'backgroundColor',
      type: 'color',
      friendlyName: 'Custom background color',
    },
    {
      name: 'backgroundColorHover',
      type: 'color',
      friendlyName: 'Custom background color on hover',
    },
    {
      name: 'borderWidth',
      type: 'string',
      friendlyName: 'Custom border width (ex. 4px)',
    },
    {
      name: 'borderStyle',
      type: 'string',
      defaultValue: 'solid',
      enum: ['none', 'solid', 'dashed', 'dotted', 'double'],
      friendlyName: 'Custom border style',
    },
    { name: 'borderColor', type: 'color', friendlyName: 'Custom border color' },
    {
      name: 'borderColorHover',
      type: 'color',
      friendlyName: 'Custom border color on hover',
    },
    {
      name: 'buttonHeight',
      type: 'string',
      friendlyName: 'Custom button height (ex. 54px)',
    },
  ],
})

Builder.registerComponent(
  dynamic(() => import('~/components/ResourcePostTiles')),
  {
    name: 'ResourcePostTiles',
    inputs: [
      {
        name: 'filteredCategory',
        friendlyName: 'Filtered Category',
        type: 'string',
        description: 'Single Category to show on landing page',
        helperText: 'Single Category to show on landing page',
      },
      {
        name: 'initialCategory',
        friendlyName: 'Initial Category Filter',
        type: 'string',
        defaultValue: 'All',
        description: 'Initial category to filter by',
        helperText:
          'Initial Category filter if not single category page (for "All" posts page)',
        showIf: (options) => !options.get('filteredCategory'),
      },
      {
        name: 'initialTag',
        friendlyName: 'Initial Tag Filter',
        type: 'string',
        defaultValue: 'All',
        description: 'Initial tag to filter by',
      },
      {
        name: 'postsPerPage',
        friendlyName: 'Max Posts Per Page',
        type: 'number',
        defaultValue: '15',
        description: 'Number of posts per page',
        helperText: 'Max Posts Per Page',
      },
      {
        name: 'sortBy',
        friendlyName: 'Sort Order',
        type: 'string',
        enum: ['dateAsc', 'dateDesc'],
        defaultValue: 'dateDesc',
        description: 'Sort order for posts',
      },
      {
        name: 'hideExcerpt',
        friendlyName: 'Hide Excerpt on all tiles',
        helperText: 'Hide excerpt from thumbnail card',
        type: 'boolean',
      },
      {
        name: 'hideExcerptOnMobile',
        friendlyName: 'Hide Excerpt on mobile tiles',
        helperText: 'Hide excerpt from mobile devices thumbnail card',
        type: 'boolean',
      },
      {
        name: 'hideTags',
        friendlyName: 'Hide tags on tiles',
        helperText: 'Hide tags on tiles',
        type: 'boolean',
      },

      {
        name: 'hidePagination',
        friendlyName: 'Hide Pagination',
        helperText: 'Toggle on/off to show and hide pagination',
        type: 'boolean',
      },

      {
        friendlyName: 'Pagination Color',
        name: 'paginationColor',
        type: 'color',
        helperText: 'Color of Pagination text',
        defaultValue: '#000000',
      },

      {
        friendlyName: 'Pagination Hover Color',
        name: 'paginationHoverColor',
        type: 'color',
        helperText: 'Color of Pagination text on hover',
        defaultValue: '#000000',
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(() => import('~/components/BreadCrumbs')),
  {
    name: 'BreadCrumbs',
    inputs: [
      {
        name: 'contentTitle',
        type: 'string',
        defaultValue: 'All Content',
      },
      {
        name: 'contentPath',
        type: 'string',
        defaultValue: '/',
      },
      {
        name: 'postTitle',
        type: 'string',
        defaultValue: 'Post',
        helperText: 'Will be bound to the title from resourcetiles in Builder.',
      },
    ],
  }
)

Builder.registerComponent(
  dynamic(() => import('~/components/UI-Kit/Icons')),
  {
    name: 'Icons',
    inputs: [
      {
        name: 'icon',
        type: 'string',
        enum: [
          'action-copy',
          'action-download-pdf',
          'action-favorite',
          'action-log-out',
          'action-settings',
          'action-upload',
          'amenities-education',
          'amenities-firepit',
          'amenities-fireplace',
          'amenities-gym',
          'amenities-laundry',
          'amenities-noise',
          'amenities-parking',
          'amenities-pool',
          'amenities-trash',
          'amenities-wifi',
          'communication-phone',
          'communication-question',
          'documents-clipboard',
          'documents-disclosure',
          'documents-flag',
          'documents-inspec',
          'documents-inspection',
          'documents-insurance',
          'documents-permit',
          'documents-prepurchase',
          'documents-repairs',
          'documents-report',
          'documents-title',
          'financial-pay',
          'financial-payment',
          'financial-payment-method',
          'financial-purchase',
          'financial-secure-cc',
          'financial-secure-payment',
          'forms-date',
          'forms-email',
          'forms-name',
          'forms-search',
          'forms-www',
          'home-building',
          'home-exterior',
          'home-foundation',
          'home-garage',
          'home-interior',
          'home-items-dresser',
          'home-listing-status',
          'home-neighborhood',
          'home-rental-listing',
          'home-roof',
          'home-roof-v2',
          'home-use',
          'lens-buyer-lg',
          'lens-buyer-sm',
          'lens-inspector-lg',
          'lens-inspector-sm',
          'lens-insurance-lg',
          'lens-insurance-sm',
          'navigational-account',
          'navigational-ascending',
          'navigational-caret-down',
          'navigational-caret-left',
          'navigational-caret-right',
          'navigational-caret-up',
          'navigational-descending',
          'navigational-expand',
          'navigational-external-link',
          'navigational-hidden',
          'navigational-menu',
          'navigational-navigate',
          'navigational-tooltip',
          'navigational-visible',
          'outdoors-acres',
          'outdoors-eco',
          'outdoors-frontyard',
          'outdoors-outdoors',
          'risk-tsunami-lg',
          'risk-tsunami-sm',
          'risks-contamination-lg',
          'risks-contamination-sm',
          'risks-crime-lg',
          'risks-crime-sm',
          'risks-drought-lg',
          'risks-drought-sm',
          'risks-earth-disturbance-lg',
          'risks-earth-disturbance-sm',
          'risks-earthquake-lg',
          'risks-earthquake-sm',
          'risks-fire-protection-lg',
          'risks-fire-protection-sm',
          'risks-flooding-lg',
          'risks-flooding-sm',
          'risks-hail-lg',
          'risks-hail-sm',
          'risks-health-safety-lg',
          'risks-health-safety-sm',
          'risks-hurricane-lg',
          'risks-hurricane-sm',
          'risks-lightning-lg',
          'risks-lightning-sm',
          'risks-pollution-lg',
          'risks-pollution-sm',
          'risks-sinkhole-lg',
          'risks-sinkhole-sm',
          'risks-thunderstorm-lg',
          'risks-thunderstorm-sm',
          'risks-tornado-lg',
          'risks-tornado-sm',
          'risks-volcano-lg',
          'risks-volcano-sm',
          'risks-weather-and-natural-disasters-lg',
          'risks-weather-and-natural-disasters-sm',
          'risks-wildfire-lg',
          'risks-wildfire-sm',
          'risks-wind-lg',
          'risks-wind-sm',
          'risks-winter-lg',
          'risks-winter-sm',
          'rooms-attic',
          'rooms-baby-room',
          'rooms-basement',
          'rooms-bathroom',
          'rooms-bedroom',
          'rooms-gameroom',
          'rooms-kitchen',
          'rooms-living-room',
          'space-boundary',
          'space-expansion',
          'space-lot',
          'space-map',
          'space-sq-ft',
          'space-stories',
          'subsections-detailed-history',
          'subsections-estimated-utility',
          'subsections-exposure-weather',
          'subsections-exterior-condition',
          'subsections-flood-risk-lg',
          'subsections-flood-risk-sm',
          'subsections-health-safety-lg',
          'subsections-health-safety-sm',
          'subsections-home-care-services',
          'subsections-home-warranties',
          'subsections-homeowner-service-history',
          'subsections-inspection-history',
          'subsections-insuring-property',
          'subsections-listing-details',
          'subsections-modifications',
          'subsections-ownership',
          'subsections-permit-history',
          'subsections-possible-contamination',
          'subsections-property-details',
          'subsections-risks-glance',
          'subsections-spotlight',
          'subsections-terms-definitions',
          'systems-ac',
          'systems-all',
          'systems-electrical',
          'systems-heating',
          'systems-plumbing',
        ],
      },
      {
        name: 'size',
        type: 'string',
        defaultValue: 'md',
        enum: ['sm', 'md', 'lg', 'xl', 'xxl'],
      },
      { name: 'color', type: 'color' },
    ],
  }
)

const MapSearch = dynamic(() => import('~/components/MapSearch'))

Builder.registerComponent(MapSearch, {
  name: 'MapSearch',
  inputs: [
    {
      friendlyName: 'Redirect URL',
      helperText:
        'Sends submission to another page. Use when CheckoutManager does not exist on the same page.',
      name: 'redirectUrl',
      type: 'string',
      required: true,
    },
    {
      friendlyName: 'Input Label',
      name: 'inputLabel',
      type: 'string',
    },
    {
      friendlyName: 'Default Input Address ',
      name: 'defaultAddress',
      type: 'string',
    },
    {
      friendlyName: 'Button Label',
      name: 'buttonLabel',
      type: 'string',
    },
    {
      friendlyName: 'Input Radius',
      name: 'inputRadius',
      type: 'string',
      defaultValue: 'medium',
      enum: ['small', 'medium', 'large', 'full'],
    },
    {
      friendlyName: 'Button Radius',
      name: 'buttonRadius',
      type: 'string',
      defaultValue: 'sm',
      enum: ['sm', 'md', 'lg', 'xl', 'full'],
    },
    {
      friendlyName: 'Button Color',
      name: 'buttonColor',
      type: 'color',
      helperText: 'Color of search button',
    },
    {
      name: 'buttonIcon',
      friendlyName: 'Button Icon (Remix Icon)',
      type: 'string',
      helperText:
        'Navigate to https://remixicon.com/ and grab the icon name you would like to use. Ex. The icon name for https://remixicon.com/icon/search-eye-line is search-eye-line',
    },
    {
      name: 'buttonIconColor',
      friendlyName: 'Icon Color',
      type: 'color',
      defaultValue: '#E0E0E0',
    },
    {
      name: 'buttonIconSize',
      friendlyName: 'Icon Size',
      type: 'string',
      defaultValue: 'md',
      enum: ['xs', 'sm', 'md', 'lg', 'xl'],
    },
    {
      friendlyName: 'Same Page as CheckoutManager',
      name: 'isSamePageCheckout',
      helperText:
        'If the MapSearch component is on the same page as the CheckoutManager component, this needs to be set to true',
      type: 'boolean',
    },
    {
      friendlyName: 'Column Layout',
      name: 'isColumnLayout',
      helperText: 'Adjust layout to a column rather than a row',
      type: 'boolean',
    },
  ],
})

const CheckoutManager = dynamic(() => import('~/components/CheckoutManager'))
const CheckoutManagerWithChildren = withChildren(CheckoutManager)

Builder.registerComponent(CheckoutManagerWithChildren, {
  name: 'CheckoutManager',
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: true,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      defaultValue: 'Get FREE Sample Report',
      friendlyName: 'Klaviyo Free Sample Report CTA Text',
      helperText: 'CTA text for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Klaviyo Free Sample Report Form Id',
      helperText: 'ID for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormId',
      type: 'string',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
    {
      name: 'showBackButtons',
      type: 'boolean',
      defaultValue: true,
      friendlyName: 'Show Back Button',
    },
    {
      friendlyName: 'Back Button CTA Text',
      helperText: 'CTA text for the checkout flow Back button',
      name: 'backButtonCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Cancel Button CTA Text',
      helperText: 'CTA text for the checkout flow Cancel button',
      name: 'cancelButtonCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Checkout Button CTA Text',
      helperText: 'CTA text for the checkout action',
      name: 'checkoutButtonCtaText',
      type: 'string',
    },
    {
      name: 'showStripeLoader',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Stripe Loader',
    },
    {
      friendlyName: 'Redirect URL Parameters',
      name: 'redirectParameters',
      helperText:
        'This appends url parameters to the url the user gets redirected to after completing the payment.',
      type: 'list',
      subFields: [
        {
          friendlyName: 'Parameter Name',
          name: 'parameterName',
          type: 'string',
        },
        {
          friendlyName: 'Parameter Value',
          name: 'parameterValue',
          type: 'string',
        },
      ],
    },
  ],
})

const CheckoutManagerV1_1 = dynamic(
  () => import('~/components/CheckoutManagerV1_1')
)
const CheckoutManagerV1_1WithChildren = withChildren(CheckoutManagerV1_1)

Builder.registerComponent(CheckoutManagerV1_1WithChildren, {
  name: 'CheckoutManagerV1_1',
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: true,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      defaultValue: 'Get FREE Sample Report',
      friendlyName: 'Klaviyo Free Sample Report CTA Text',
      helperText: 'CTA text for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Klaviyo Free Sample Report Form Id',
      helperText: 'ID for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormId',
      type: 'string',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
    {
      name: 'showBackButtons',
      type: 'boolean',
      defaultValue: true,
      friendlyName: 'Show Back Button',
    },
    {
      friendlyName: 'Back Button CTA Text',
      helperText: 'CTA text for the checkout flow Back button',
      name: 'backButtonCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Cancel Button CTA Text',
      helperText: 'CTA text for the checkout flow Cancel button',
      name: 'cancelButtonCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Checkout Button CTA Text',
      helperText: 'CTA text for the checkout action',
      name: 'checkoutButtonCtaText',
      type: 'string',
    },
    {
      name: 'showStripeLoader',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Stripe Loader',
    },
    {
      friendlyName: 'Redirect URL Parameters',
      name: 'redirectParameters',
      helperText:
        'This appends url parameters to the url the user gets redirected to after completing the payment.',
      type: 'list',
      subFields: [
        {
          friendlyName: 'Parameter Name',
          name: 'parameterName',
          type: 'string',
        },
        {
          friendlyName: 'Parameter Value',
          name: 'parameterValue',
          type: 'string',
        },
      ],
    },
  ],
})

const CheckoutManagerV1_2 = dynamic(
  () => import('~/components/CheckoutManagerV1_2')
)
const CheckoutManagerV1_2WithChildren = withChildren(CheckoutManagerV1_2)

Builder.registerComponent(CheckoutManagerV1_2WithChildren, {
  name: 'CheckoutManagerV1_2',
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: true,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      defaultValue: 'Get FREE Sample Report',
      friendlyName: 'Klaviyo Free Sample Report CTA Text',
      helperText: 'CTA text for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Klaviyo Free Sample Report Form Id',
      helperText: 'ID for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormId',
      type: 'string',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
    {
      name: 'showPropertyInfo',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Property Info',
    },
    {
      name: 'showBackButtons',
      type: 'boolean',
      defaultValue: true,
      friendlyName: 'Show Back Button',
    },
    {
      friendlyName: 'Back Button CTA Text',
      helperText: 'CTA text for the checkout flow Back button',
      name: 'backButtonCtaText',
      type: 'string',
    },
    {
      name: 'showStripeLoader',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Stripe Loader',
    },
    {
      friendlyName: 'Redirect URL Parameters',
      name: 'redirectParameters',
      helperText:
        'This appends url parameters to the url the user gets redirected to after completing the payment.',
      type: 'list',
      subFields: [
        {
          friendlyName: 'Parameter Name',
          name: 'parameterName',
          type: 'string',
        },
        {
          friendlyName: 'Parameter Value',
          name: 'parameterValue',
          type: 'string',
        },
      ],
    },
  ],
})

const CheckoutManagerV1_3 = dynamic(
  () => import('~/components/CheckoutManagerV1_3')
)
const CheckoutManagerV1_3WithChildren = withChildren(CheckoutManagerV1_3)

Builder.registerComponent(CheckoutManagerV1_3WithChildren, {
  name: 'CheckoutManagerV1_3',
  canHaveChildren: true,
  inputs: [
    {
      friendlyName: 'Show Promo Code',
      helperText: 'Toggle the visibility of the promo code',
      name: 'showPromoCode',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Show Cart Header',
      helperText: 'Toggle the visibility of the cart header section',
      name: 'showHeaderCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Show Product Description',
      helperText: 'Toggle the visibility of the product description',
      name: 'showProductDescription',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Custom Description Text',
      helperText: 'Text to display in the product description section',
      name: 'customProductText',
      type: 'richText',
      defaultValue:
        'Purchasing a PropertyLens Report offers a comprehensive property overview, helping consumers avoid hidden costs.',
    },

    {
      friendlyName: 'Bundle Text',
      helperText: 'Text to display for our bundle deals',
      name: 'bundleText',
      type: 'string',
      defaultValue: 'Multiple addresses? Bundle & SAVE!',
    },

    {
      friendlyName: 'Custom Radio Label',
      helperText: 'Report credits text',
      name: 'customRadioLabel',
      type: 'string',
      defaultValue:
        'Choose number of report credits to buy... credits never expire!',
    },

    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: true,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      defaultValue: 'Get FREE Sample Report',
      friendlyName: 'Klaviyo Free Sample Report CTA Text',
      helperText: 'CTA text for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Klaviyo Free Sample Report Form Id',
      helperText: 'ID for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormId',
      type: 'string',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
    {
      name: 'showStripeLoader',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Stripe Loader',
    },
    {
      friendlyName: 'Redirect URL Parameters',
      name: 'redirectParameters',
      helperText:
        'This appends url parameters to the url the user gets redirected to after completing the payment.',
      type: 'list',
      subFields: [
        {
          friendlyName: 'Parameter Name',
          name: 'parameterName',
          type: 'string',
        },
        {
          friendlyName: 'Parameter Value',
          name: 'parameterValue',
          type: 'string',
        },
      ],
    },
  ],
})

const CheckoutManagerV1_4 = dynamic(
  () => import('~/components/CheckoutManagerV1_4')
)
const CheckoutManagerV1_4WithChildren = withChildren(CheckoutManagerV1_4)

Builder.registerComponent(CheckoutManagerV1_4WithChildren, {
  name: 'CheckoutManagerV1_4',
  canHaveChildren: true,
  inputs: [
    {
      name: 'topSection',
      type: 'uiBlocks',
      defaultValue: {
        blocks: [],
      },
    },
    {
      friendlyName: 'Show Promo Code',
      helperText: 'Toggle the visibility of the promo code',
      name: 'showPromoCode',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Show Cart Header',
      helperText: 'Toggle the visibility of the cart header section',
      name: 'showHeaderCart',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Interstitial content',
      helperText:
        'Reference the interstitial section content to be shown while the report preview API call is made.',
      name: 'interstitial',
      type: 'reference',
      model: 'interstitial',
      required: false,
    },
    {
      friendlyName: 'Minimum interstitial time (ms)',
      helperText:
        'The minimum amount of time the interstitial loading state will be shown. It may be shown longer if the API call exceeds this time.',
      name: 'minimumInterstitialTime',
      type: 'number',
    },
    {
      friendlyName: 'Variants',
      helperText:
        'The variants that are available to purchase on this page. SKUs must be valid OMS SKUs.',
      name: 'variants',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'SKU',
          name: 'sku',
          type: 'reference',
          model: 'sk-us',
          required: true,
        },
      ],
      onChange: (options) => {
        if (
          !options.get('showVariantOptions') &&
          options.get('variants').length > 1
        ) {
          const tempVariants = options.get('variants')
          tempVariants.pop()
          options.set('variants', tempVariants)
          alert('To add more Variants, you must toggle on Show Variant Options')
        }
      },
    },
    {
      friendlyName: 'Show Variant Options',
      helperText:
        'Toggle the visibility of the Variant Options. If hidden, only one Variant SKU will be given to the user.',
      name: 'showVariantOptions',
      type: 'boolean',
      defaultValue: true,
      onChange: (options) => {
        if (
          options.get('variants').length > 1 &&
          !options.get('showVariantOptions')
        ) {
          options.set('showVariantOptions', true)
          alert(
            'To hide Variant Options, you must only have 1 Variant attached to the Component'
          )
        }
      },
    },

    {
      friendlyName: 'Coupon Before Variant Options',
      helperText: 'Positions Coupons before Variant OPtions',
      name: 'couponBeforeVariantOptions',
      type: 'boolean',
      defaultValue: true,
    },
    {
      friendlyName: 'Variant Label',
      helperText: 'Text that appears as the Label of the Variant Options.',
      name: 'variantLabel',
      type: 'string',
      defaultValue:
        'Choose number of report credits to buy... credits never expire!',
    },
    {
      friendlyName: 'Variant Layout',
      name: 'variantLayout',
      type: 'string',
      defaultValue: 'radio',
      enum: ['radio', 'dropdown'],
    },
    {
      friendlyName: 'Demographic Checkbox Options',
      helperText:
        'Configure the checkboxes to be shown above the quantity selection.',
      name: 'demographicCheckboxOptions',
      type: 'list',
      subFields: [
        {
          name: 'slug',
          type: 'string',
        },
        {
          name: 'label',
          type: 'string',
        },
      ],
    },
    {
      name: 'bottomSection',
      type: 'uiBlocks',
      defaultValue: {
        blocks: [],
      },
    },
    {
      name: 'showStripeLoader',
      type: 'boolean',
      defaultValue: false,
      friendlyName: 'Show Stripe Loader',
    },
    {
      friendlyName: 'Redirect URL Parameters',
      name: 'redirectParameters',
      helperText:
        'This appends url parameters to the url the user gets redirected to after completing the payment.',
      type: 'list',
      subFields: [
        {
          friendlyName: 'Parameter Name',
          name: 'parameterName',
          type: 'string',
        },
        {
          friendlyName: 'Parameter Value',
          name: 'parameterValue',
          type: 'string',
        },
      ],
    },
  ],
})

const KlaviyoFormModal = dynamic(() => import('~/components/KlaviyoFormModal'))

Builder.registerComponent(KlaviyoFormModal, {
  name: 'KlaviyoFormModal',
  inputs: [
    {
      defaultValue: 'Get FREE Sample Report',
      friendlyName: 'Klaviyo Free Sample Report CTA Text',
      helperText: 'CTA text for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormCtaText',
      type: 'string',
    },
    {
      friendlyName: 'Klaviyo Free Sample Report Form Id',
      helperText: 'ID for the Klaviyo Sample Report Email Popup',
      name: 'klaviyoSampleFormId',
      type: 'string',
    },
  ],
})

const FAQAccordion = dynamic(() => import('~/components/FAQAccordion'))

Builder.registerComponent(FAQAccordion, {
  name: 'FAQAccordion',
  noWrap: true,
  inputs: [
    {
      friendlyName: 'FAQ Items',
      helperText:
        'Add faq items from the faq model to display in the accordion.',
      name: 'faqItems',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'FAQ Item',
          name: 'faqItem',
          type: 'reference',
          model: 'faqItems',
          required: true,
        },
      ],
    },
  ],
})

const GoogleMap = dynamic(() => import('~/components/GoogleMap'))

Builder.registerComponent(GoogleMap, {
  name: 'GoogleMap',
  inputs: [
    {
      name: 'latitude',
      type: 'string',
      defaultValue: '+32.8149770',
      required: true,
      friendlyName: 'Latitude',
      helperText: 'Valid latitude between -90 and +90 degrees',
    },
    {
      name: 'longitude',
      type: 'string',
      defaultValue: '-96.1355615',
      required: true,
      friendlyName: 'Longitude',
      helperText: 'Valid longitude between -180 and +180 degrees',
    },
  ],
})

const InfoItemsSlider = dynamic(() => import('~/components/InfoItemsSlider'))

Builder.registerComponent(InfoItemsSlider, {
  name: 'InfoItemsSlider',
  inputs: [
    {
      friendlyName: 'Info Items',
      helperText:
        'Add info items from the infoItems model to display in the slider.',
      name: 'infoItems',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'Info Item',
          name: 'infoItem',
          type: 'reference',
          model: 'infoItems',
          required: true,
        },
      ],
    },
  ],
})

const BarChart = dynamic(() => import('~/components/BarChart'))

Builder.registerComponent(BarChart, {
  name: 'BarChart',
  inputs: [
    {
      name: 'chartName',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'fill',
      type: 'color',
      defaultValue: '#8884d8',
    },
    {
      friendlyName: 'Table Column Titles',
      helperText:
        'Add chart title for the data to display the column header in the table.',
      name: 'tableColumnTitles',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'tableColumnTitle',
          type: 'string',
          defaultValue: 'Table Column Title',
          required: true,
          friendlyName: 'Table Column Title',
          helperText: 'Table Column Title on the header of the table',
        },
      ],
    },
    {
      friendlyName: 'Chart Items',
      helperText:
        'Add chart title and amount from the chartItems model to display in the chart.',
      name: 'chartItems',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'barColumnTitle',
          type: 'string',
          defaultValue: 'Bar Column Title',
          required: true,
          friendlyName: 'Bar Column Title',
          helperText: 'Bar Column Title on the x-Axis of the chart item',
        },
        {
          name: 'amountTitle',
          type: 'string',
          defaultValue: 'Amount Title',
          required: true,
          friendlyName: 'Amount Title',
          helperText: 'Amount Title of the chart item',
        },
        {
          name: 'amount',
          type: 'string',
          defaultValue: '0',
          required: true,
          friendlyName: 'Amount',
          helperText: 'Amount of the chart item',
        },
      ],
    },
  ],
})

const AreaChart = dynamic(() => import('~/components/AreaChart'))

Builder.registerComponent(AreaChart, {
  name: 'AreaChart',
  inputs: [
    {
      name: 'chartName',
      type: 'string',
      defaultValue: '',
    },
    {
      name: 'fill',
      type: 'color',
      defaultValue: '#8884d8',
    },
    {
      name: 'fillOpacity',
      type: 'number',
      defaultValue: 0.3,
    },
    {
      friendlyName: 'Table Column Titles',
      helperText:
        'Add chart title for the data to display the column header in the table.',
      name: 'tableColumnTitles',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'tableColumnTitle',
          type: 'string',
          defaultValue: 'Table Column Title',
          required: true,
          friendlyName: 'Table Column Title',
          helperText: 'Table Column Title on the header of the table',
        },
      ],
    },
    {
      name: 'stroke',
      type: 'color',
      defaultValue: '#82ca9d',
    },
    {
      friendlyName: 'Chart Items',
      helperText:
        'Add chart title and amount from the chartItems model to display in the chart.',
      name: 'chartItems',
      type: 'list',
      required: true,
      subFields: [
        {
          name: 'areaColumnTitle',
          type: 'string',
          defaultValue: 'Area Column Title',
          required: true,
          friendlyName: 'Area Column Title',
          helperText: 'Area Column Title on the x-Axis of the chart item',
        },
        {
          name: 'amountTitle',
          type: 'string',
          defaultValue: 'Amount Title',
          required: true,
          friendlyName: 'Amount Title',
          helperText: 'Amount Title of the chart item',
        },
        {
          name: 'amount',
          type: 'string',
          defaultValue: '0',
          required: true,
          friendlyName: 'Amount',
          helperText: 'Amount of the chart item',
        },
      ],
    },
  ],
})

const LandingPageHeadlines = dynamic(
  () => import('~/components/LandingPageHeadlines')
)

Builder.registerComponent(LandingPageHeadlines, {
  name: 'LandingPageHeadlines',
  noWrap: true,
  inputs: [
    {
      friendlyName: 'URL Parameter',
      name: 'urlParameter',
      type: 'string',
      defaultValue: 'utm_content',
      helperText:
        'Parameter to check if exists in url (e.g. utm_content, utm_source, pl_source)',
    },
    {
      friendlyName: 'Fallback Headline',
      name: 'fallbackHeadline',
      type: 'richText',
      helperText: 'Fallback headline if parameter match is null',
    },
    {
      friendlyName: 'Fallback Subheadline',
      name: 'fallbackSubheadline',
      type: 'richText',
      helperText: 'Fallback subheadline if parameter match is null',
    },
  ],
})

const LandingPageHeroBackground = dynamic(
  () => import('~/components/LandingPageHeroBackground')
)

Builder.registerComponent(LandingPageHeroBackground, {
  name: 'LandingPageHeroBackground',
  noWrap: true,
  inputs: [
    {
      name: 'heroBackgrounds',
      type: 'json',
      description:
        'Enter a JSON array for hero backgrounds. Example: [{"sourceId": "id1", "imageUrl": "url1.jpg"}]',
    },
  ],
})

const LandingPageHero = dynamic(() => import('~/components/LandingPageHero'))

Builder.registerComponent(LandingPageHero, {
  name: 'LandingPageHero',
  inputs: [
    {
      name: 'heroData',
      type: 'json',
      description:
        'Enter a JSON array for hero data. Example: [{"sourceId": "walls_talk-001", "title": "Buying a house?", "headline": "<p>If those walls could talk...</em></strong></p>"}]',
    },
  ],
})

const PrimaryButton = dynamic(() => import('~/components/PrimaryButton'))

Builder.registerComponent(PrimaryButton, {
  name: 'PrimaryButton',
  inputs: [
    {
      name: 'buttonLabel',
      type: 'string',
      defaultValue: 'Buy Now $99 (or bundle & SAVE!)',
    },
  ],
})

const SpeedGauge = dynamic(() => import('~/components/SpeedGauge'))

Builder.registerComponent(SpeedGauge, {
  name: 'SpeedGauge',
  inputs: [
    {
      name: 'descendingMaxToMin',
      type: 'boolean',
      defaultValue: false,
      helperText: 'Show color grade green to red or red to green (default)',
    },
    {
      name: 'description',
      type: 'string',
    },
    {
      name: 'indexValue',
      type: 'number',
      defaultValue: 1,
    },
    {
      name: 'maxValue',
      type: 'number',
      defaultValue: 200,
    },
    {
      name: 'minValue',
      type: 'number',
      defaultValue: 0,
    },
    {
      friendlyName: 'Number of Levels',
      name: 'nrOfLevels',
      type: 'string',
      enum: ['3', '4', '5'],
      helperText: 'Number of color levels in the gauge (3, 4 or 5)',
    },
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Index',
    },
  ],
})

const TableOfContents = dynamic(() => import('~/components/TableOfContents'))

Builder.registerComponent(TableOfContents, {
  name: 'TableOfContents',
  inputs: [
    {
      name: 'title',
      type: 'string',
      defaultValue: 'Table of Contents',
    },
  ],
})

const TestimonialsSlider = dynamic(
  () => import('~/components/TestimonialsSlider')
)

Builder.registerComponent(TestimonialsSlider, {
  name: 'TestimonialsSlider',
  inputs: [
    {
      friendlyName: 'Testimonials',
      helperText: 'The testimonials that are available from real customers.',
      name: 'testimonials',
      type: 'list',
      required: true,
      subFields: [
        {
          friendlyName: 'Testimonial',
          name: 'testimonial',
          type: 'reference',
          model: 'testimonials',
          required: true,
        },
      ],
    },
  ],
})

const WowFactor = dynamic(() => import('~/components/WowFactor'))
Builder.registerComponent(WowFactor, {
  name: 'WowFactor',
  inputs: [
    {
      name: 'backgroundColor',
      type: 'color',
      defaultValue: 'pink',
    },
    {
      name: 'title',
      type: 'richText',
      defaultValue: 'This is a headline',
    },
    {
      name: 'iconName',
      friendlyName: 'Remix Icon Name',
      type: 'string',
      defaultValue: 'star-fill',
      helperText:
        'Navigate to https://remixicon.com/ and grab the icon name you would like to use. Ex. The icon name for https://remixicon.com/icon/star-fill is star-fill',
    },
    {
      name: 'iconColor',
      friendlyName: 'Remix Icon Color',
      type: 'color',
      defaultValue: '#000',
    },
    {
      name: 'iconSize',
      friendlyName: 'Remix Icon Size',
      type: 'string',
      defaultValue: 'md',
      enum: ['sm', 'md', 'lg', 'xl'],
    },
  ],
  canHaveChildren: true,
  defaultChildren: [
    {
      '@type': '@builder.io/sdk:Element',
      component: {
        name: 'Text',
        options: {
          text: 'This is Builder text',
        },
      },
    },
  ],
})

function createColors(colors) {
  const asdf = []
  for (const [key, value] of Object.entries(colors)) {
    asdf.push({
      name: key,
      value: `var(--theme-ui-colors-${key}, ${value})`,
    })
  }
  return asdf
}

Builder.register('editor.settings', {
  allowOverridingTokens: true,
  designTokensOptional: true,
  designTokens: {
    colors: createColors(colors),
    spacing: [
      { name: 'Large', value: 'var(--space-large, 20px)' },
      { name: 'Small', value: 'var(--space-small, 10px)' },
      { name: 'Tiny', value: '5px' },
    ],
    fontFamily: [
      {
        name: 'Raleway',
        value: 'Raleway, -apple-system, Roboto, Helvetica, sans-serif',
      },
      {
        name: 'Inter',
        value: 'Inter, -apple-system, Roboto, Helvetica, sans-serif',
      },
    ],
  },
})
